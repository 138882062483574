<template>
  <div ref="pageBox">
    <SearchBox ref="SearchBox">
      <el-form slot="form" :inline="true" label-width="80px" class="unitWidth" size="small">
        <el-form-item label="标题">
          <el-input v-model="query.searchName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="活动类型">
          <el-select v-model="value" @change="hanledatapicker" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template slot="btns">
        <el-button size="small" @click="selrele()" type="primary">搜索</el-button>
      </template>
    </SearchBox>
    <LabelContent title="活动召集审核" ref="LabelContent"> </LabelContent>
    <div class="tableBox">
      <el-table
        stripe
        ref="multipleTable"
        :data.sync="tableData"
        class="center"
        v-loading="loading"
        :height="tableHeight + 'px'"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        :default-sort="{ prop: 'value', order: 'descending' }"
      >
        <el-table-column prop="title" label="活动标题" />
        <el-table-column prop="content" label="活动内容" show-overflow-tooltip />
        <el-table-column label="图片/视频" show-overflow-tooltip>
          <template slot-scope="slot">
            <el-image
              v-if="slot.row.hasOwnProperty('files')"
              :preview-src-list="[slot.row.files[0].updUrl]"
              :src="slot.row.files[0].updUrl"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="creatorName" label="发布人" show-overflow-tooltip />
        <el-table-column prop="activityType.desc" label="活动类型" show-overflow-tooltip />
        <el-table-column prop="activityAddress" label="活动地址" show-overflow-tooltip />
        <el-table-column prop="activityStartTime" label="活动时间" show-overflow-tooltip />
        <el-table-column
          prop="isCollect"
          label="是否置顶"
          :formatter="isCollect"
          show-overflow-tooltip
        />
        <el-table-column prop="auditStatus.desc" label="审核状态" show-overflow-tooltip />
        <el-table-column prop="createTime" label="生成时间" show-overflow-tooltip />
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" @click="tableDetail(scope.row)">审核</el-button>
            <!-- <el-button type="primary">置顶</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="paginationChange"
        @current-change="paginationChange"
        :current-page="query.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Factory",
  data () {
    return {
      tableHeight: 0, //表格高度
      tableData: [],
      total: 0,
      query: {
        activityType: "",
        pageNum: 1,
        pageSize: 10,
        timeStart: "",
        timeEnd: "",
        searchName: "",
      },
      options: [{
        value: 'on_line',
        label: '线上'
      }, {
        value: 'off_line',
        label: '线下'
      }],
      value: '',
      menus: []
    }
  },
  mounted () {
    this.loading = true
    //设置表格高度 
    this.$nextTick(() => {
      let page = this.$refs.pageBox.offsetHeight || 0 //卡片高度
      let SearchBox = this.$refs.SearchBox
        ? this.$refs.SearchBox.$el.offsetHeight
        : 0 //搜索框高度
      let LabelContent = this.$refs.LabelContent
        ? this.$refs.LabelContent.$el.offsetHeight
        : 0 //内容标题高度
      this.tableHeight = page - (SearchBox + LabelContent + 20 + 30) //padding -20,分页 -30
    })
    this.init()
    this.init2()
  },
  methods: {
    //查询 
    selrele () {
      this.init()
    },
    hanledatapicker () {
      console.log(123)
      this.query.activityType = this.value
      console.log(this.query.activityType)
      this.init()
    },
    async init2 () {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/user/self",
        method: "GET",
      })
      if (result.status == 100) {
        let { data } = result
        that.menus = data.authorities
      }
      console.log(that.menus)
    },
    //活动召集列表数据
    async init () {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/activity/page",
        method: "GET",
        params: that.query,
      })
      if (result.status == 100) {
        let { data } = result
        that.tableData = data.list
        that.total = data.total
        that.loading = false
      }
    },
    batchDelete () {
      let deleteData = this.batchDeleteArr.map((item) => {
        const { server_asset_id } = item
        return { server_asset_id }
      })
      this.deleteApi(deleteData)
    },
    isCollect (row, column) {
      return row.isTop ? "是" : "否"
    },
    deleteApi (data) {
      const _this = this
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => { })
    },
    //分页
    paginationChange (val) {
      console.log(val)
    },
    //表格行过滤
    formatterRow (row, column) {
      console.log(row)
    },
    //多选框选择 返回当前已选行数据(Array)
    handleSelectionChange (val) {
      this.batchDeleteArr = val
      console.log(val)
    },
    //从后台获取数据,重新排序
    sortChange (val) {
      // 详细文档参考： https://element.eleme.cn/2.13/#/zh-CN/component/table
      console.log(val)
    },
    //自定义排序规则 sort-method
    sortByDate (a, b) {
      console.log(a, b)
      if (a < b) return -1
    },

    //跳转审核
    tableDetail (row) {
      this.$router.push({
        path: "/acti_details/" + row.atyId,
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button--primary {
  width: 4.5vw;
  height: 2vw;
  line-height: 0px;
}
.stywid {
  margin-left: 1vw;
}
.stywid1 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 2.5vw;
}
</style>
